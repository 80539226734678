import React from 'react'
import { View, Text, Image, TouchableOpacity, Dimensions, Platform } from 'react-native'
import { colours, gutter } from '../styles/constants'
import isWeb from '../helpers/isWeb'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'
import globalVars from '../helpers/globalVars'
import ConnectionManager from '../helpers/ConnectionManager'
import ImageManager from '../helpers/ImageManager'

const width = Dimensions.get('window').width

const getWrappersStyle = (exceedsMediumBreakpoint) => {
  const padding = isWeb ? {
    paddingTop: exceedsMediumBreakpoint ? 28 : 18,
    paddingRight: gutter.small,
    paddingBottom: exceedsMediumBreakpoint ? 28 : 18,
    paddingLeft: gutter.small
  } : {
    paddingTop: Platform.OS === 'ios' ? 0 : 30,
    paddingRight: gutter.small,
    paddingBottom: 5,
    paddingLeft: gutter.small
  }

  return {
    width: '100%',
    ...(isWeb ? {
      backgroundColor: colours.primary
    } : {
      backgroundColor: colours.lighterGrey,
      color: colours.primary
    }),
    ...padding
  }
}

const getHeadingsStyle = (exceedsMediumBreakpoint, headingLength) => {
  let fontSize = isWeb ? (exceedsMediumBreakpoint ? 24 : 30) : 19

  if (!isWeb && headingLength) {
    if (headingLength > 20) {
      fontSize = 15
    }
  }

  return {
    fontSize,
    fontFamily: 'greycliff-extrabold',
    lineHeight: lineHeight(45),
    textAlign: 'center',
    color: isWeb ? 'white' : colours.primary
  }
}

const Welcome = {
  Container: View,
  HeadingWrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      ...getWrappersStyle(exceedsMediumBreakpoint),
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  Heading: ({ exceedsMediumBreakpoint, style = {}, children, hassmalltitle, small, headingLength, ...props }) => {
    const _style = {
      ...getHeadingsStyle(exceedsMediumBreakpoint, headingLength),
      ...style
    }

    if (isWeb && hassmalltitle) {
      _style.display = exceedsMediumBreakpoint ? 'block' : 'none'
    }

    if (isWeb && small) {
      _style.display = exceedsMediumBreakpoint ? 'none' : 'block'
    }
    return <Text style={_style} {...props}>{children}</Text>
  },
  SubHeadingWrapper: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      ...getWrappersStyle(exceedsMediumBreakpoint),
      paddingTop: 0,
      paddingBottom: 20,
      ...style
    }
    return <View style={_style} {...props}>{children}</View>
  },
  SubHeading: ({ exceedsMediumBreakpoint, style = {}, children, ...props }) => {
    const _style = {
      ...getHeadingsStyle(exceedsMediumBreakpoint, 0),
      textAlign: 'left',
      fontSize: width / 12,
      lineHeight: lineHeight(width / 11),
      ...style
    }
    return <Text style={_style} {...props}>{children}</Text>
  },
  TouchableOpacity: TouchableOpacity,
  Back: Image
}

const styles = {
  touchableOpacity: {
    position: 'absolute',
    left: 15,
    width: 50,
    top: Platform.OS === 'ios' ? 13 : 42,
    zIndex: 1
  },
  back: {
    width: 12,
    height: 21
  },
  headerLogo: {
    height: 47,
    width: 80,
    right: 20,
    top: Platform.OS === 'ios' ? 0 : 28,
    position: 'absolute',
    resizeMode: 'contain'
  }
}

export default class Component extends React.Component {
  state = {
    exceedsMediumBreakpoint: false,
    companyLogoUrl: null
  }

  loadImageAsBase64 = async (companyLogoUrl) => {
    // eslint-disable-next-line
    const base64 = await FileSystem.readAsStringAsync(companyLogoUrl, {
    // eslint-disable-next-line
      encoding: FileSystem.EncodingType.Base64
    })
    return `data:image/png;base64,${base64}` // Cambia "png" si es necesario
  }

  componentDidMount () {
    BreakpointWatcher.addComponent(this)

    const showLogo = !isWeb && globalVars.companyLogoUrl != null
    if (showLogo) {
      ConnectionManager.getManager().checkApiConnectivity().then((isOnline) => {
        // A bit hacky but react native URL parser doesn't support pathname
        const pathComponents = globalVars.companyLogoUrl.split('/')
        const fileName = pathComponents[pathComponents.length - 1]

        const im = ImageManager.getManager(isOnline)

        im.getImageUrl(fileName, globalVars.companyLogoUrl, null).then(async companyLogoUrl => {
          const imageUri = await this.loadImageAsBase64(companyLogoUrl)

          this.setState({ companyLogoUrl: imageUri })
        })
      })
    }
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
  }

  render () {
    const { back, title, smallTitle, subHeading, nativeID } = this.props

    const { exceedsMediumBreakpoint, companyLogoUrl } = this.state

    const hasBackButton = back && back.show && !isWeb

    const titleLength = (title == null) ? 0 : title.length

    return (
      <Welcome.Container nativeID={nativeID}>
        <Welcome.HeadingWrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
          {hasBackButton &&
            <Welcome.TouchableOpacity style={styles.touchableOpacity} onPress={back.link} activeOpacity={0.7}>
              <Welcome.Back style={styles.back} source={require('../images/arrow-left-primary.png')} />
            </Welcome.TouchableOpacity>
          }
          {companyLogoUrl != null && <Image style={styles.headerLogo} source={{ uri: companyLogoUrl }} />}
          <View>
            <Welcome.Heading exceedsMediumBreakpoint={exceedsMediumBreakpoint} hassmalltitle={smallTitle} headingLength={titleLength}>{title}</Welcome.Heading>
            {smallTitle && <Welcome.Heading exceedsMediumBreakpoint={exceedsMediumBreakpoint} small="true">{smallTitle}</Welcome.Heading>}
          </View>
        </Welcome.HeadingWrapper>
        {subHeading &&
          <Welcome.SubHeadingWrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
            <View>
              <Welcome.SubHeading exceedsMediumBreakpoint={exceedsMediumBreakpoint}>{subHeading}</Welcome.SubHeading>
            </View>
          </Welcome.SubHeadingWrapper>
        }
      </Welcome.Container>
    )
  }
}
